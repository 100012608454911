import constants from '@/components/map/style/constants'
import { constrainedSize, lineWidth, State } from '@/components/map/style/util'
import { Feature } from 'ol'
import { Circle, Fill, Stroke, Style, Text } from 'ol/style'
import { FeaturePropEnum } from '@/components/map/LayerItemConfig'

function getHue(hueMin: number, hueMax: number, valueMin: number, valueMax: number, value: number) {
  const hueRange = hueMax - hueMin
  const valueRange = valueMax - valueMin

  return hueMin + ((value - valueMin) * hueRange) / valueRange
}

function strokeStyle(resolution: number, state: State = 'default') {
  return new Stroke({
    width: 2 * pointSize(resolution) + 4,
    color: constants.mediaGroup.color()
  })
}

function pointSize(resolution: number) {
  return Math.ceil(lineWidth(resolution) * 0.8)
}

export function loadFlowStyle(feature: Feature, resolution: number) {
  return [
    new Style({
      stroke: strokeStyle(resolution)
    }),
    new Style({
      image: new Circle({
        radius: pointSize(resolution),
        fill: new Fill({ color: feature.get(FeaturePropEnum.color) })
      }),
      zIndex: 1000 + feature.get(FeaturePropEnum.UR_abs)
    })
  ]
}

export function loadFlowMaxVoltageStyle(feature: Feature, resolution: number) {
  return new Style({
    image: new Circle({
      radius: pointSize(resolution) + 2,
      stroke: new Stroke({
        color: 'black',
        width: 2
      })
    }),
    text: new Text({
      placement: 'point',
      textAlign: 'right',
      text: feature.get(FeaturePropEnum.voltage),
      offsetX: -3 * pointSize(resolution),
      offsetY: -2 * pointSize(resolution),
      font: `bold ${constrainedSize(13, 15, resolution)}px ${constants.fontFamily}`,
      fill: new Fill({ color: 'black' }),
      stroke: new Stroke({ color: 'rgba(255,255,255,0.8)', width: 4 })
    })
  })
}
