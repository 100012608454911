<script lang="ts">
import ResultTable from '@/components/map/controls/calculation/ResultTable.vue'
import { useProject } from '@/composables/useProject'
import { useCalculationStore } from '@/stores/calculation'
import { useSystemStore } from '@/stores/system'
import { csvToExcelCsv, csvToJson } from '@/util'
import { formatNumber, triggerDownload } from '@/util/helpers'
import { CalculationResultData } from '@gridside/hsb-api'
import { ElMessage } from 'element-plus'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ResultTab',
  components: { ResultTable },
  setup() {
    const { project } = useProject()
    return {
      calculationStore: useCalculationStore(),
      project,
      systemStore: useSystemStore()
    }
  },

  data: () => ({
    formatNumber,
    selectedSubResult: 0 as number | false
  }),

  computed: {
    csvData() {
      return (this.isPipeProject ? this.resultData?.loadFlow : this.resultData?.eMF) || ''
    },

    isPipeProject() {
      return this.project?.mediaType === 'pipe'
    },

    result() {
      return this.calculationStore.result
    },

    operationStates() {
      return this.calculationStore.current?.studyCase?.operationStates || []
    },

    operationStateData() {
      if (this.selectedSubResult === false) {
        return undefined
      }
      return (
        this.calculationStore.current?.studyCase?.operationStates[this.selectedSubResult] ||
        undefined
      )
    },

    resultData(): CalculationResultData | undefined {
      if (this.selectedSubResult === false) {
        return this.result
      } else {
        return this.result?.subresult ? this.result?.subresult[this.selectedSubResult] : undefined
      }
    },

    selectItems() {
      type ItemType = Array<{ value: number | false; label: string; maxVoltage?: number }>
      const items: ItemType = this.isPipeProject
        ? [{ value: false, label: 'Gesamtergebnis', maxVoltage: this.result?.resultVoltage }]
        : []

      if (this.result?.subresult) {
        items.push(
          ...this.result.subresult.map((_, index) => ({
            value: index,
            maxVoltage: this.result?.subresult
              ? this.result.subresult[index]?.resultVoltage
              : undefined,
            label: `Teilergebnis ${index + 1}`
          }))
        )
      }
      return items
    },

    studyCase() {
      return this.calculationStore.current?.studyCase
    },

    system() {
      return this.operationStateData
        ? this.systemStore.findById(this.operationStateData.system)
        : undefined
    },

    tableData() {
      if (this.isPipeProject) {
        return csvToJson(this.resultData?.loadFlow || '')
      } else {
        return csvToJson(this.resultData?.eMF || '')
      }
    }
  },

  methods: {
    async copyTableToClipboard() {
      const excelCsv = csvToExcelCsv(this.csvData)
      await navigator.clipboard.writeText(excelCsv)
      ElMessage.success(
        'Daten wurden in die Zwischenablage kopiert, Sie können sie in Excel einfügen.'
      )
    },

    async downloadCsv() {
      const excelCsv = csvToExcelCsv(this.csvData, true, ';')
      const fileName = this.getDownloadFileName('csv')
      triggerDownload(excelCsv, fileName, 'text/csv')
    },

    async downloadGeoJson() {
      const geoJsonData = this.isPipeProject
        ? this.resultData?.loadFlowGeojson
        : this.resultData?.emfResult
      if (!geoJsonData || !this.studyCase) {
        ElMessage.error('Es sind keine GeoJSON-Daten vorhanden.')
        return
      }

      const fileName = this.getDownloadFileName('geojson')
      triggerDownload(JSON.stringify(geoJsonData), fileName, 'application/geo+json')
    },

    getDownloadFileName(extension: string) {
      return `${this.studyCase?.name} - ${
        this.selectedSubResult === false
          ? 'Gesamtergebnis'
          : 'Teilergebnis ' + (this.selectedSubResult + 1)
      }.${extension}`
    },

    selectDefaultSubResult() {
      this.selectedSubResult = this.isPipeProject ? false : 0
    }
  },

  watch: {
    result() {
      this.selectDefaultSubResult()
    }
  }
})
</script>

<template>
  <div>
    <div class="flex gap-6 w-full mb-4 items-center">
      <el-select v-model="selectedSubResult" class="flex-shrink-0 !w-60">
        <el-option
          v-for="item in selectItems"
          :label="item.label"
          :value="item.value"
          :key="item.value"
        >
          <SystemTag
            v-if="item.value !== false"
            :system="operationStates[item.value].system"
            class="min-w-[1.5em] !p-1 text-xs mr-1"
            style="height: auto !important"
          >
            {{ item.value + 1 }}
          </SystemTag>
          {{ item.label }}
          <span v-if="item.maxVoltage !== undefined" class="text-gray-400 ml-2 font-normal text-xs">
            {{ $n(item.maxVoltage, { maximumFractionDigits: 1 }) }} V
          </span>
        </el-option>
      </el-select>
      <div
        v-if="resultData"
        class="py-2 px-1 flex items-center"
        :class="{ 'flex-1 justify-end': !operationStateData }"
      >
        <template v-if="isPipeProject">φ</template>
        <template v-else>U</template>
        <sub>max</sub>
        <span class="ml-4 font-bold whitespace-nowrap">
          {{ $n(resultData.resultVoltage, { maximumFractionDigits: 1 }) }} V
        </span>
      </div>
      <div v-if="operationStateData && system" class="flex-1 flex items-center gap-x-2">
        <SystemTag :system />
        <div
          v-for="(conductorState, index) in operationStateData.conductorStates"
          :key="index"
          class="border rounded px-2 py-1 text-sm bg-white whitespace-nowrap"
        >
          <span class="font-semibold">L{{ index + 1 }}:</span>
          {{ formatNumber(conductorState.current, 'A') }}
          ∠
          {{ formatNumber(conductorState.angle, '°') }}
        </div>
      </div>
      <div class="whitespace-nowrap">
        <el-dropdown size="small">
          <span class="el-dropdown-link text-xs mr-4 mt-1">
            Rohdaten
            <el-icon class=""><ChevronDownIcon /></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item icon="CopyIcon" @click="copyTableToClipboard">
                CSV in Zwischenablage kopieren
              </el-dropdown-item>
              <el-dropdown-item icon="DownloadIcon" @click="downloadCsv">
                CSV herunterladen
              </el-dropdown-item>
              <el-dropdown-item icon="DownloadIcon" @click="downloadGeoJson">
                GeoJSON herunterladen
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <ResultTable :items="tableData" />
  </div>
</template>

<style scoped lang="css"></style>
