import { defineStore } from 'pinia'
import {
  OverheadLine,
  Project,
  ReplaceTowersRequest,
  Span,
  Tower,
  TowerRequest,
  TowerResponse
} from '@gridside/hsb-api'
import { OverheadLineId } from '@/model/overhead-line'
import { v4 } from 'uuid'
import { HsbApi } from '@/api'
import { useCorridorStore } from '@/stores/corridor'
import { useTowerTypeStore } from '@/stores/tower-type'
import { Feature, FeatureCollection, LineString } from 'geojson'
import { useProject } from '@/composables/useProject'
import { copy } from '@/util'

type TowerId = TowerResponse['id']
type SpanId = Span['id']
type ProjectId = Project['id']

type OverheadLineRecord = {
  overheadLine: OverheadLine
  towersById: Record<TowerId, TowerResponse>
  spansById: Record<SpanId, Span>
}

type OverheadLineDraft = {
  overheadLine: Partial<OverheadLine>
  path: Array<{ x: number; y: number }>
  tower?: Partial<TowerResponse>
}

export const useOverheadLineStore = defineStore('overheadLine', {
  state: () => ({
    useProject: useProject(),
    loadedProjectId: undefined as ProjectId | undefined,
    itemsById: {} as Record<OverheadLineId, OverheadLineRecord>,
    itemsToDelete: [] as OverheadLine[],
    loaded: false,
    loading: false,
    overheadLineDraft: undefined as OverheadLineDraft | undefined,
    selection: [] as OverheadLineId[],
    spansSelection: [] as SpanId[],
    towerDraft: undefined as Partial<TowerResponse> | undefined,
    towersSelection: [] as TowerId[]
  }),

  getters: {
    items(): OverheadLineRecord[] {
      return Object.values(this.itemsById).sort((a, b) =>
        a.overheadLine.name.localeCompare(b.overheadLine.name)
      )
    },

    overheadLineGeoJSON(): FeatureCollection<LineString> {
      const features: Feature<LineString>[] = Object.values(this.itemsById).map(
        (overheadLineRecord) => {
          const towerCoordinates = Object.values(overheadLineRecord.towersById).map((tower) => {
            return [tower.x, tower.y]
          })

          return {
            type: 'Feature',
            properties: {
              _type: 'overheadLine',
              name: overheadLineRecord.overheadLine.name
            },
            id: overheadLineRecord.overheadLine.id,
            geometry: {
              type: 'LineString',
              coordinates: towerCoordinates
            }
          }
        }
      )

      if (this.overheadLineDraft) {
        features.push({
          type: 'Feature',
          id: this.overheadLineDraft.overheadLine.id,
          properties: {
            _draft: true,
            _type: 'overheadLine',
            ...this.overheadLineDraft.overheadLine
          },
          geometry: {
            type: 'LineString',
            coordinates: this.overheadLineDraft.path.map((item) => [item.x, item.y])
          }
        })
      }
      return {
        type: 'FeatureCollection',
        features: features.filter((feature) => feature.geometry.coordinates.length > 0)
      }
    },

    towersAll(): TowerResponse[] {
      const towersList: TowerResponse[] = []
      for (const record of this.items) {
        towersList.push(...Object.values(record.towersById))
      }
      return towersList
    },

    towersGeoJSON(): FeatureCollection {
      const features: Feature[] = []
      const towers: Tower[] = this.towersAll

      towers.forEach((tower) => {
        features.push({
          id: tower.id,
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [tower.x, tower.y]
          },
          properties: {
            _type: 'tower',
            name: tower.name,
            position: tower.position,
            overheadLine: tower.overheadLine
          }
        })
      })

      return {
        type: 'FeatureCollection',
        features
      }
    },

    spansAll(): Span[] {
      const spanList: Span[] = []
      for (const record of this.items) {
        spanList.push(...Object.values(record.spansById))
      }
      return spanList
    },

    spansGeoJSON(): FeatureCollection {
      const features: Feature[] = []
      const spans = this.spansAll

      spans.forEach((span) => {
        const beginTower = this.towersAll.find((tower) => tower.id === span.beginTower)
        const endTower = this.towersAll.find((tower) => tower.id === span.endTower)

        if (beginTower && endTower) {
          features.push({
            id: span.id,
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [
                [beginTower.x, beginTower.y],
                [endTower.x, endTower.y]
              ]
            },
            properties: {
              _type: 'span',
              beginTower: span.beginTower,
              endTower: span.endTower,
              corridor: span.corridor,
              overheadLine: span.overheadLine
            }
          })
        }
      })

      return {
        type: 'FeatureCollection',
        features
      }
    }
  },

  actions: {
    findById(id: string): undefined | OverheadLineRecord {
      return id ? this.itemsById[id] : undefined
    },

    /**
     * Used for type-save overhead-line access (no undefined)
     * @throws Error
     * @param id
     */
    findByIdOrFail(id: OverheadLineId): OverheadLineRecord {
      const record = this.itemsById[id]
      if (!record) {
        throw Error(`OverheadLine mit id ${id} nicht gefunden`)
      }
      return record
    },

    async delete(id: OverheadLineId) {
      const item = this.findById(id)
      if (item) {
        try {
          await HsbApi.overheadLines.deleteOverheadLine(
            item.overheadLine.project,
            item.overheadLine.id
          )
          delete this.itemsById[id]
        } finally {
          this.loading = false
        }
      }
    },

    /**
     * Load store based on URL projectId param
     */
    async ensureLoaded() {
      const projectId = this.useProject.projectId
      const projectChanged = this.loadedProjectId !== projectId
      if (!(this.loaded || this.loading) || projectChanged) {
        await this.load(projectId)
      }
    },

    /**
     * Load all OverheadLines to a project
     * @param projectId
     */
    async load(projectId: ProjectId) {
      if (this.loadedProjectId !== projectId) {
        this.loaded = false
      }
      this.loading = true
      this.itemsById = {}

      if (!projectId) {
        throw new Error('OverheadLineStore.load: ProjectId must not be empty.')
      }

      try {
        const jobs: Promise<any>[] = []
        const overheadLinesResponse = await HsbApi.overheadLines.getOverheadLines(projectId)
        overheadLinesResponse.results.forEach((item) => {
          const job = this.upsertOverheadLineRecord(item)
          jobs.push(job)
        })
        await Promise.all(jobs)
        this.loadedProjectId = projectId
        this.loaded = true
      } finally {
        this.loading = false
      }
    },

    /**
     * Used to insert/update a OverheadLine
     * @param overheadLine
     */
    async upsertOverheadLineRecord(overheadLine: OverheadLine) {
      const record: OverheadLineRecord = createEmptyRecord(overheadLine)

      // fill with towers and spans
      await Promise.all([this.towersLoad(record), this.spansLoad(record)])

      // set record in store
      this.itemsById[overheadLine.id] = record

      return record
    },

    async save(item: OverheadLine) {
      try {
        this.loading = true
        const updatedItem = await HsbApi.overheadLines.saveOverheadLine(item.project, item.id, item)
        const record = this.findById(updatedItem.id)

        if (record) {
          record.overheadLine = updatedItem
        } else {
          this.itemsById[updatedItem.id as OverheadLineId] = createEmptyRecord(item)
        }

        return updatedItem
      } finally {
        this.loading = false
      }
    },

    lastTower(overheadLineId: OverheadLineId): TowerResponse | undefined {
      const record = this.findById(overheadLineId)
      if (!record) {
        return undefined
      }
      return Object.values(record.towersById).sort(
        (a, b) => (b?.position || 0) - (a?.position || 0)
      )[0]
    },

    ////////////////////////////////////
    ////////////// Towers //////////////
    ////////////////////////////////////

    findTowerById(towerId: TowerId): TowerResponse | undefined {
      for (const item of this.items) {
        if (towerId in item.towersById) {
          return item.towersById[towerId]
        }
      }
      return undefined
    },

    async towersLoad(record: OverheadLineRecord): Promise<Record<string, TowerResponse>> {
      record.towersById = {}
      this.towersSelection = []

      const response = await HsbApi.towers.getTowersByOverheadline(
        record.overheadLine.project,
        record.overheadLine.id
      )

      response.results.forEach((item) => {
        fixTowerPosition(item, 'load')
        record.towersById[item.id] = item
      })

      return record.towersById
    },

    async towersReplaceAll(
      payload: ReplaceTowersRequest,
      overheadLineId: string
    ): Promise<Record<string, TowerResponse>> {
      const record = this.findByIdOrFail(overheadLineId)
      // fix tower positions
      for (const tower of payload.list) {
        fixTowerPosition(tower, 'save')
      }

      await HsbApi.towers.replaceAllTowersInOverheadline(
        record.overheadLine.project,
        record.overheadLine.id,
        payload
      )

      // update overhead line (length, spans, towers)
      const overheadLine = await HsbApi.overheadLines.getOverheadLine(
        record.overheadLine.project,
        record.overheadLine.id
      )
      await this.upsertOverheadLineRecord(overheadLine)

      return record.towersById
    },

    async towerSaveBatch(towerIds: TowerId[], changeset: Partial<Tower>) {
      if (towerIds.length === 0) {
        throw Error('Mindestens ein Mast benötigt')
      }

      if (towerIds.length === 1) {
        const tower = this.findTowerById(towerIds[0])
        if (!tower) {
          throw Error('Mast nicht vorhanden')
        }
        return [await this.towerSave({ ...tower, ...changeset })]
      }

      // sort towers by overhead line (they need their own requests)
      const towersByOverheadLine: Record<OverheadLineId, TowerId[]> = {}
      towerIds.forEach((towerId) => {
        const tower = this.findTowerById(towerId)
        if (!tower) {
          return
        }
        if (!towersByOverheadLine[tower.overheadLine]) {
          towersByOverheadLine[tower.overheadLine] = []
        }
        towersByOverheadLine[tower.overheadLine].push(towerId)
      })

      // method so send the actual request for the given overhead line
      const saveBatchByOverheadLine = async (
        overheadLineId: OverheadLineId,
        towerIds: TowerId[]
      ) => {
        const overheadLineRecord = this.findByIdOrFail(overheadLineId)
        const response = await HsbApi.towers.batchUpdateTowers(
          overheadLineRecord.overheadLine.project,
          overheadLineId,
          { items: towerIds, data: changeset }
        )
        for (const updatedItem of response.results) {
          fixTowerPosition(updatedItem, 'load')
          overheadLineRecord.towersById[updatedItem.id] = updatedItem
        }
        return response.results
      }

      const allUpdatedTowers: Tower[] = []
      for (const overheadLineId in towersByOverheadLine) {
        try {
          this.loading = true
          const updatedTowers = await saveBatchByOverheadLine(
            overheadLineId,
            towersByOverheadLine[overheadLineId]
          )
          allUpdatedTowers.push(...updatedTowers)
        } finally {
          this.loading = false
        }
      }
      return allUpdatedTowers
    },

    async towerSave(item: Tower) {
      try {
        if (!item.id) {
          item.id = v4()
        }
        let relatedTowers = item.relatedTowers || []

        this.loading = true
        const overheadLineRecord = this.findByIdOrFail(item.overheadLine)
        const projectId = overheadLineRecord.overheadLine.project

        const corridorStore = useCorridorStore()
        const towerTypeStore = useTowerTypeStore()
        await towerTypeStore.ensureLoadedByProject(projectId)

        // 1. Sync lengths of earthwires
        // See: https://app.clickup.com/t/24584978/HSB-659
        const earthwiresCountIn = towerTypeStore.earthwireCountForType(item.in.type)
        const earthwiresCountOut = towerTypeStore.earthwireCountForType(item.out?.type)
        item.in.earthwires.length = earthwiresCountIn
        if (item.out?.earthwires) {
          item.out.earthwires.length = earthwiresCountOut
        }

        // 2. Perform API Call & store result
        const updatedItem = await HsbApi.towers.saveTower(
          projectId,
          item.overheadLine,
          item.id,
          fixTowerPosition(item, 'save')
        )
        fixTowerPosition(updatedItem, 'load')
        overheadLineRecord.towersById[item.id] = updatedItem

        // 3. Update tower position indices from server (may have changed after duplicate or move)
        await this.towersUpdatePositions(overheadLineRecord)
        this.towerDraft = undefined

        // 4. Reload towers from server that were or are freshly related to the saved tower
        relatedTowers.push(...(updatedItem.relatedTowers || []))
        relatedTowers = relatedTowers.filter(
          (relatedItem) =>
            relatedItem.tower && relatedItem.overheadLine && relatedItem.tower !== item.id
        )

        for (const relatedItem of relatedTowers) {
          const tower = this.findTowerById(relatedItem.tower!)
          if (tower) {
            const currentData = await HsbApi.towers.getTower(
              projectId,
              tower.overheadLine,
              tower.id
            )

            this.itemsById[tower.overheadLine].towersById[tower.id] = fixTowerPosition(
              currentData,
              'load'
            ) as TowerResponse
          }
        }

        // 5. Refresh corridor, spans and overhead line (length) data
        corridorStore.load(projectId)
        this.spansLoad(overheadLineRecord)
        const overheadLineUpdated = await HsbApi.overheadLines.getOverheadLine(
          projectId,
          overheadLineRecord.overheadLine.id
        )
        await this.upsertOverheadLineRecord(overheadLineUpdated)

        return fixTowerPosition(updatedItem, 'load')
      } finally {
        this.loading = false
      }
    },

    createTowerDraft(overheadLineId: OverheadLineId, data: Partial<Tower>) {
      const record = this.findById(overheadLineId)
      if (!record) {
        return undefined
      }

      let defaultData = {}
      // Use last tower for defaults
      const towers = Object.values(record.towersById)
      if (towers.length > 0) {
        const lastTower = towers.slice(-1)[0]
        defaultData = { ...lastTower, name: undefined, position: undefined }
      }
      this.towerDraft = copy({ ...defaultData, id: v4(), ...data, overheadLine: overheadLineId })
      return this.towerDraft
    },

    async towerDelete(id: string) {
      const tower = this.towersAll.find((item) => item.id === id)
      if (tower) {
        try {
          this.loading = true
          const overheadLineRecord = this.findByIdOrFail(tower.overheadLine)
          await HsbApi.towers.deleteTower(
            overheadLineRecord.overheadLine.project,
            overheadLineRecord.overheadLine.id,
            tower.id
          )
          delete overheadLineRecord.towersById[tower.id]
          this.itemsById[overheadLineRecord.overheadLine.id] = overheadLineRecord // spread is needed to trigger reactivity
          this.towersUpdatePositions(overheadLineRecord)
        } finally {
          this.loading = false
        }
      }
    },

    async towersUpdatePositions(overheadLineRecord: OverheadLineRecord) {
      const response = await HsbApi.towers.getTowersByOverheadline(
        overheadLineRecord.overheadLine.project,
        overheadLineRecord.overheadLine.id
      )

      response.results.forEach((item) => {
        fixTowerPosition(item, 'load')
        const towerInStore = overheadLineRecord.towersById[item.id]
        if (towerInStore) {
          towerInStore.position = item.position
        }
      })
    },

    ////////////////////////////////////
    ////////////// Spans  //////////////
    ////////////////////////////////////

    findSpanById(spanId: TowerId): Span | undefined {
      for (const item of this.items) {
        if (spanId in item.spansById) {
          return item.spansById[spanId]
        }
      }
      return undefined
    },

    async spansLoad(record: OverheadLineRecord) {
      record.spansById = {}
      this.spansSelection = []

      const response = await HsbApi.spans.getSpansByOverheadLine(
        record.overheadLine.project,
        record.overheadLine.id
      )
      response.results.forEach((item) => {
        record.spansById[item.id] = item
      })
      return record.spansById
    },

    async spanSaveBatch(spanIds: SpanId[], changeset: Partial<Span>) {
      if (spanIds.length === 0) {
        throw Error('Mindestens ein Spannfeld benötigt')
      }

      if (spanIds.length === 1) {
        const span = this.findSpanById(spanIds[0])
        if (!span) {
          throw Error('Spannfeld nicht vorhanden')
        }

        return [await this.spanSave({ ...span, ...changeset })]
      }

      // sort spans by overhead line
      const spansByOverheadLine: Record<OverheadLineId, Span[]> = {}
      spanIds.forEach((spanId) => {
        const span = this.findSpanById(spanId)
        if (!span) {
          return
        }
        if (!spansByOverheadLine[span.overheadLine]) {
          spansByOverheadLine[span.overheadLine] = []
        }
        spansByOverheadLine[span.overheadLine].push(span)
      })

      // method to send the actual request for the given overhead line
      const saveBatchByOverheadLine = async (overheadLineId: OverheadLineId, spans: Span[]) => {
        const overheadLineRecord = this.findByIdOrFail(overheadLineId)
        const response = await HsbApi.spans.batchUpdateSpans(
          overheadLineRecord.overheadLine.project,
          overheadLineId,
          { items: spans.map((span) => span.id), data: changeset }
        )
        for (const updatedItem of response.results) {
          overheadLineRecord.spansById[updatedItem.id] = updatedItem
        }
        return response.results
      }

      const allUpdatedSpans: Span[] = []
      for (const overheadLineId in spansByOverheadLine) {
        try {
          this.loading = true
          const updatedSpans = await saveBatchByOverheadLine(
            overheadLineId,
            spansByOverheadLine[overheadLineId]
          )
          allUpdatedSpans.push(...updatedSpans)
        } finally {
          this.loading = false
        }
      }
      return allUpdatedSpans
    },

    async spanSave(item: Span) {
      try {
        this.loading = true
        const overheadLineRecord = this.findByIdOrFail(item.overheadLine)
        const updatedItem = await HsbApi.spans.saveSpan(
          overheadLineRecord.overheadLine.project,
          overheadLineRecord.overheadLine.id,
          item.id,
          item
        )
        overheadLineRecord.spansById[item.id] = updatedItem
        this.updateCorridor(overheadLineRecord.overheadLine.project)
        return updatedItem
      } finally {
        this.loading = false
      }
    },
    /**
     * After update of spans the corridor data needs to be refreshed
     * @param projectId
     */
    updateCorridor(projectId: ProjectId) {
      useCorridorStore().load(projectId)
    },

    getSpanPosition(span: Span) {
      const record = this.findByIdOrFail(span.overheadLine)
      return Object.values(record.spansById).findIndex((item) => item.id === span.id)
    }
  }
})

function createEmptyRecord(overheadLine: OverheadLine): OverheadLineRecord {
  return {
    overheadLine,
    spansById: {},
    towersById: {}
  }
}

/**
 * Changes tower position in-place.
 * Because tower position is stored with 0-Index but its required to display it 1-Index based.
 */
export function fixTowerPosition<T extends TowerResponse | TowerRequest>(
  tower: T,
  mode: 'load' | 'save'
): T {
  if (tower.position === undefined) {
    tower.position = mode === 'load' ? 1 : undefined
    return tower
  }

  if (mode === 'save') {
    tower.position = tower.position - 1
    if (tower.position < 0) {
      throw Error('Tower Position kann nicht kleiner als 0 sein!')
    }
    return tower
  }

  if (mode === 'load') {
    tower.position = tower.position + 1
    return tower
  }
  return tower
}
